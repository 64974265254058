import React from 'react';
import Header from './header';
import Body from './body';

const Products = () => (
  <>
    <Header />
    <Body />
  </>
);

export default Products;
